import {useState} from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import LilFroksNFT from './LilFroks.json';
//const ethers = require("ethers");

const LilFroksAddress = "0x49fc3f5e43dae0aa1ecaca79c5a28bb03fe910fd";

const MainMint = ({accounts,setAccounts}) =>{
    const [mintAmount, setMintAmount] = useState(1);
    const [mintsAmount, setMintsAmount] = useState(0);
    const [NumberToMint, setNumberToMint]= useState(5);
    const [userBalance, setuserBalance]= useState(0);
    
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const statusModal = document.getElementById('statusModal');
    const txStatus = document.getElementById('txStatus');

    const txpulse = document.getElementById('pulse');
    const txpulseconfirmed = document.getElementById('pulseconfirmed');
    const txpulsefailed = document.getElementById('pulsefailed');

    let signer = null;
    let provider;
    
    async function handleMint(){
        
        const ethVal =NumberToMint * 0.009999 * 10**18;
        console.log(ethVal)
        try{

        if (isConnected){
    
            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()
            const MintContract = new Contract(LilFroksAddress, LilFroksNFT.abi, signer)
            var overrideOptions = {
                value: ethVal.toString()
            };
            
            statusModal.style.display = 'block';
            txStatus.innerText = 'Transaction Sent. Please confirm in your wallet and wait.';

           const response = await MintContract.mint(address,NumberToMint,overrideOptions);

           const receipt = await response.wait();
           
          //  console.log(receipt);
           if (receipt.status === 1) {
            const txhash = receipt.hash;
           // console.log(txhash)
            txpulse.style.display = "none";
            txpulseconfirmed.style.display="block";
            txStatus.innerHTML = 'Transaction Confirmed. Minting successful!<BR><BR><a class="txlink" target="_blank" href="https://etherscan.io/tx/'+txhash+'">View transaction on Etherscan</a>';
        } else {
            txpulse.style.display = "none";
            txpulsefailed.style.display="block";
            txStatus.innerText = 'Transaction Failed.';
            
        }

        }
            }catch{
                txpulse.style.display = "none";
                txpulsefailed.style.display="block";
                txStatus.innerText = 'Transaction Failed.';
            }
        
    }
    
    async function getMints2() {
        
        try{
        if (isConnected){
   
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const MintContract = new Contract(LilFroksAddress, LilFroksNFT.abi, signer)
        const numMints = await MintContract.totalSupply()
        const userBalance = await MintContract.balanceOf(address);
       // console.log(userBalance);
        setMintsAmount(9999-parseInt(numMints));
        setuserBalance(userBalance);
        }
        setTimeout(getMints2, 5000);
    }
    catch{

    }
      }



        
        


   const nameChangeHandler = (e) => {
    if(e.target.value <= 8){
      setNumberToMint(e.target.value);
    }else{
      setNumberToMint(8);
    }
    
};


getMints2();


return(
    <div className="mintbox">

    <p className="paratxt">Lil Froks are a collection of 9,999 cute, colorful & unique frogs on the Ethereum blockchain.</p>
        <img id="minthdr" alt="Mint Froks" src="mint.png" />
        {!isConnected ?(<div><br/>Connect to mint</div>):('')}
       {isConnected && mintsAmount > 1 ? (
    <div className="qtyholder"><div className="minttext">Mint: <div className="maxtext">Max 8 per TX<br/>(weird, we know..)<br/><br/>0.009999 ETH Each</div></div><input className="mintQTY" onChange={nameChangeHandler} value={NumberToMint} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
        <div className="mintholder"><button className="mintbtn" onClick={handleMint} >MINT</button></div>
        </div>
       ):('')} 
       {mintsAmount > 1 ?(
        <div className="remainingtext">Remaining: {mintsAmount.toString()} <br />You Own: {userBalance.toString()}</div>
       ):('')
       }
       
    </div>
)
}

export default MainMint;