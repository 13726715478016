import React from 'react';

const NavBar = ({accounts, setAccounts}) => {
    const isConnected  = Boolean(accounts[0]);

    async function connectAccount(){
        if(window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_accounts",
            });
            setAccounts(accounts);
            
        }
    }

    return(
        <div>
        <div className="NavBar"><w3m-button balance='hide'/></div>
        </div>
    )
};

export default NavBar;