import {useState} from 'react';
import './App.css';
import MainMint from './MainMint';
import NavBar from './NavBar';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

const projectId = '8c1fcb1425438ea7addd46f866d4d258'
function App() {
  const [accounts, setAccounts] = useState([]);

  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }
  
  const testnet = {
    chainId: 11155111,
    name: 'Sepolia',
    currency: 'SepoliaETH',
    explorerUrl: 'https://sepolia.etherscan.io/',
    rpcUrl: 'https://rpc.sepolia.org'
  }

  const localhost = {
    chainId: 1337,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'http://127.0.0.1:8545'
  }

  // 3. Create modal
  const metadata = {
    name: 'Lil Froks',
    description: 'Connect to mint',
    url: 'http://localhost', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }
  
  try{
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    themeVariables: {
      '--w3m-background-color': '#000000',
      '--w3m-accent':'#000000',
      '--w3m-font-family':'Lilita One',
      
    }
  })
}
catch{
  
}

  return (
    <div className="App">
      <NavBar accounts={accounts} setAccounts={setAccounts} />
      <MainMint accounts={accounts} setAccounts={setAccounts} />
      <div className="roadmaphdr">LIL FROKS ROADMAP</div>
      <div className="infoRow">
        <div className="infoCol colDiv">
        <div className="colHdr">PHASE 1</div>
        Mint<br/>
        OG SNAPSHOTS<br/>
        Community Building
        </div>
        <div className="infoCol colDiv">
        <div className="colHdr">PHASE 2</div>
        Ecosystem Bootstrap<br/>
        AIRDROPS AIRDROPS AIRDROPS!<br/>
        Community Building
        </div>
        <div className="infoCol">
        <div className="colHdr">PHASE 3</div>
        3D Avatar Claim<br/>
        METAVERSE RAIDS<br/>
        Community Building
        </div>
      </div>

      <div className="roadmaphdr">FROKANOMICS</div>
      <div className="infoRow">
        <div className="infoCol nomics1">
        &nbsp;
        </div>
        <div className="infoCol">
        Max Froks: 9,999<br/>
        Treasury Supply: 50<br/>
        Mint Price: 0.009999 ETH
        <br/><br/>
        <a href="https://discord.gg/sezaFQgu" target="_blank" className="bottomlink">discord</a> | <a href="https://twitter.com/LilFroks"  target="_blank" className="bottomlink">twitter</a> | <a href="https://opensea.io/collection/lil-froks"  target="_blank" className="bottomlink">opensea</a> | <a href="https://etherscan.io/address/0x49fc3f5e43dae0aa1ecaca79c5a28bb03fe910fd"  target="_blank" className="bottomlink">etherscan</a>
        <br/>
        <br/>
        </div>
        <div className="infoCol">

        </div>

      </div>
    </div>
  );
}

export default App;
